import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ReactCardFlip from "react-card-flip";
import { isMobile } from "react-device-detect";
import { SvgBlob } from "react-svg-blob";

const FlipCard = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 300px;
  height: 400px;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 20px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) => props.theme.sizes.mobile} {
    /* background-color: orange; */
    width: 80vw;
    height: 50vh;
  }
  ${(props) => props.theme.sizes.tablet} {
    /* background-color: blue; */
    width: 40vw;
    height: 400px;
  }
  ${(props) => props.theme.sizes.laptop} {
    /* background-color: green; */
    width: 275px;
    height: 425px;
  }
`;

const Front = styled(FlipCard)`
  h3 {
    /* background-color: orange; */
    font-size: 30px;
    text-align: left;
    padding: 0px 20px;
    z-index: 2;

    ${(props) => props.theme.sizes.mobile} {
      /* background-color: blue; */
      padding: 0px 25px;
      text-align: center;
    }
  }
  p {
    /* background-color: yellow; */
    font-size: 50px;
    padding: 10px 20px;
    z-index: 2;
  }
`;

const Back = styled(FlipCard)`
  padding: 0px 30px;

  p {
    font-size: 0.9em;
    text-align: left;
    overflow-y: scroll;
  }

  a {
    margin-top: 10px;
    color: ${(props) => props.theme.colors.color3};
    display: block;
  }

  div {
    /* background-color: yellow; */
    color: ${(props) => props.theme.colors.color4};
    font-size: 50px;
    transform: rotate(45deg);
    padding: 0px 20px;
    position: absolute;
    top: -5px;
    right: 10px;
  }
`;

const BlobContainer = styled.div`
  height: 300px;
  width: 300px;
  position: absolute;
  z-index: 0;
`;

function generateShapeProps() {
  return {
    size: 200,
    growth: getRandomInt(4, 9),
    edges: getRandomInt(3, 15),
  };
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Card = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: isMobile ? 50 : -50 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{ once: true }}
      transition={{
        type: "spring",
        stiffness: 100,
        delay: isMobile ? 0.1 : (props.index + 1) * 0.05,
        scale: { delay: 0 },
      }}
      whileHover={{ scale: 1.05 }}
    >
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
      >
        <Front onClick={handleClick}>
          <h3>{props.question}</h3>
          <BlobContainer>
            <SvgBlob
              variant="gradient"
              colors={["#F3F1ED", "#ECEDE9"]}
              shapeProps={generateShapeProps()}
              // isOutline={Math.random() < 0.5}
            />
          </BlobContainer>
        </Front>

        <Back onClick={handleClick}>
          <div>+</div>
          <p>
            {props.answer}
            {props.index === 7 && (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.toronto.ca/community-people/health-wellness-care/health-programs-advice/bodysafe/manicures-and-pedicures/"
              >
                Toronto Health & Wellness
              </a>
            )}
          </p>
        </Back>
      </ReactCardFlip>
    </motion.div>
  );
};

export default Card;
