import * as React from "react";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import SpaceHolder from "../components/Space";
import Card from "../components/faq/Card";
import SideText from "../components/SideText";

const Wrapper = styled.div`
  /* border: 1px solid black; */
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  text-align: center;
`;

const FaqGroup = styled.div`
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BottomText = styled.h4`
  /* background-color: orange; */
  width: 80%;
  padding-bottom: 5px;

  ${(props) => props.theme.sizes.mobile} {
    width: 90%;
  }
  ${(props) => props.theme.sizes.tablet} {
    width: 90%;
  }

  a {
    color: ${(props) => props.theme.colors.color3};
    &:focus,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`;

const FaqPage = () => {
  const faqMap = new Map([
    [
      "what is your cancellation policy?",
      "cancellations made within less than 24 hours of your appointment will induce a charge of 50% of the service price. this policy also applies to gift card reservations.",
    ],

    [
      "what is your refund policy?",
      "due to the nature of the services we provide, we are unable to offer refunds.",
    ],

    [
      "what payments do you accept?",
      "we accept debit and all major credit cards including Visa, MasterCard and American Express.",
    ],

    [
      "do you accept walk-ins?",
      "walk-ins and last minute appointments are possible but not guaranteed. we recommend making a reservation in advance.",
    ],

    [
      "what happens if I am late to my appointment? ",
      "we kindly ask you to arrive a few minutes early. being late for your appointment may result in a reduction of the service or full cancellation with a 50% charge of the treatment price. so, if you are running late, please notify us as soon as possible.",
    ],

    [
      "what if I did not like the service I received? ",
      "if you are unhappy with the results of the service you received, please let us know within 72 hours of your appointment so we can fix any issues in a timely manner. our goal is to make sure you are happy. therefore, we always listen carefully to your concerns and feedback. there is nothing more important than your voice.",
    ],

    [
      "can I bring a friend or a child to my appointment?",
      "in order to ensure that all of our guests can unwind in a peaceful atmosphere, we do not allow friends, family members, or children to accompany you to your appointment.",
    ],

    [
      "can I still book an appointment if I have nail or skin infections?",
      "as per City of Toronto guidelines, please note that we are unable to see clients with nail or skin infections (e.g. nail mould or fungus). in addition, we ask that you notify your nail technician of any allergies/open wounds on your hands/feet.",
    ],

    [
      "what are your safety and sanitization protocols?",
      "we prioritize the safety and well-being of our patrons. our technicians adhere to a rigorous sanitization protocol to ensure the utmost cleanliness. in addition to thorough hand and station cleaning after each guest, our technicians are obligated to clean multi-use tools meticulously. this involves washing them with soap and water, followed by air-drying or towel-drying, submerging them in a hospital-grade disinfectant like PREempt™ CS 20™ for a minimum of 12 minutes, and ultimately rinsing, drying, and storing them in a single-use sealed bag.",
    ],

    [
      "when does my gift certificate expire?",
      "our gift certificates come without an expiration date. please note that gift certificates are non-refundable and have no cash value.",
    ],

    [
      "what is your deposit policy?",
      "while we don't mandate a deposit, we do request that you provide a credit card information for reservation purposes. this helps secure your spot and serves as a precaution in case of a no-show or a cancellation within 24 hours of the appointment start time.",
    ],

    [
      "do you have parking on premises?",
      "we do not have dedicated parking spots, but there is parking available along ossington ave.",
    ],
  ]);

  return (
    <>
      <PageLayout>
        <Navbar pageAt="faq" />
        <SpaceHolder height={80} />
        <Wrapper>
          <SideText text="faq" />
          <FaqGroup>
            {[...faqMap].map(([key, value], index) => (
              <Card key={index} question={key} answer={value} index={index} />
            ))}
          </FaqGroup>
          <SpaceHolder height={60} />
          <h2>got more questions?</h2>
          <SpaceHolder height={10} />
          <BottomText>
            please reach out to us in person, via{" "}
            <a href="tel:4168345887">phone</a> or{" "}
            <a href="mailto:hello@projectessence.ca?subject=FAQ&">email</a>.
          </BottomText>
          <BottomText>
            or send us a message to our{" "}
            <a
              href="https://www.instagram.com/projectessence.inc/"
              target="_blank"
              rel="noreferrer"
            >
              instagram
            </a>{" "}
            account.
          </BottomText>
        </Wrapper>
        <SpaceHolder height={80} />
      </PageLayout>
    </>
  );
};

export default FaqPage;

export const Head = () => <title>Project Essence</title>;
